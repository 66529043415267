import * as THREE from 'three';
import Curtain from './CurtainFbm.js';
import Stars from './Stars.js';
import Moon from './Moon.js';
import Orion from './Orion.js';
import Sun from './Sun.js';
import Meteor from './Meteor.js';
import EffectComposer, {
	RenderPass,
} from '@johh/three-effectcomposer';
import AfterimagePass from './AfterimagePass';

var interactive, container, instructions, description, menu;
var camera, scene, sceneForeground, sceneUniverse, renderer;
var time = 1.0;
var sun2, foxContainer, fox, historyGraph, historyGraphLegend, ground, foxFactContainer, dashboard ;
var worldWidth = 256, worldDepth = 256;
var clock = new THREE.Clock();
var foxFact, foxFacts, currentFoxFact, foxFactTimer, historyGraphTimer, foxFactDirty; //container for fact texts
var curtains = [];
var composer; // for postprocessing using EffectComposer
var meteor;
var pixelRatio = 0.5;
var fov = 65; //camera FOV in degrees, ~15mm lens = 100
var afterimagePass, renderPass;
var kpData = {'time_tag':'', 'kp_index': 0};
var kpTrend = [];
var kpSelected = null;
var kpDataHistory = {};
var controlParams = {};

const kpLookup = {
  'famp':[0.7, 0.8, 2.5], //amplification of texture
  'fspatialfreq':[200, 170, 1.0], //spatial frequency of fbn noise
  'ftimefreq':[14, 40, 2.7], //change rate of fbm noise
  'fspatialfreq2': [0.5, 2.0, 2.5], //spatial frequency of simple noise dampening of curtain amp
  'ftravelrate': [-4.0, -20.0, 2.0], //travel vel along curtain of fbm noise
  'fverticaldista': [0.07, 0.008, 1.5], //multiplier for vertical offset of texture with respect to curtain mesh (this is really horizontal along texture)
  'fverticaldistb': [3.0, 1.0, 2.5], //tuning of the fbm noise lower frequency amplitudes. higher is brighter overall
  'fverticaloffset': [1.0, 11, 2.5], //offset along vertical part of texture, further modulated by the fbm noise. higher is towards crazier colours
  'vamp': [0.000013, 0.000003, 2.5], //vertex shader amplitude, modulated by simple noise, perpendicular to curtain
  'vspatialfreq':[0.2, 0.4, 2.5], //...and associated spatial frequency
  'vtimefreq':[2.0, 10.0, 2.5], //and associated time frequency
  'sunpos': [-6000, -1000, 1.0],
  'foxlight': [1.0, 1.5, 1.0],
  'groundlight': [1.0, 1.5, 1.0],
  'curtainnorth':[0.015,-0.002, 1.5],
  'curtainrotation':[-0.09,-0.6, 2.9],
  'zoom':[1.0, 0.9, 2.5],
  'fov':[70, 70, 2.5],
  'panup':[25, 25, 2.5]
}

const kpColours = [
  '#1c5687', //kp0
  '#3483aa',
  '#3fabd2',
  '#a282f5',
  '#c140fa',
  '#f131f2',
  '#efa950',
  '#ed8e2e',
  '#ea6e2a',
  '#f32348' //kp9
];

const descriptions = [
  'Quietest geomagnetic conditions',
  'Quieter geomagnetic conditions',
  'Quiet geomagnetic conditions',
  'Unsettled geomagnetic conditions',
  'Active geomagnetic conditions',
  'Minor geomagnetic storm',
  'Moderate geomagnetic storm',
  'Strong geomagnetic storm',
  'Severe geomagnetic storm',
  'Extreme geomagnetic storm'
]

const BASE_URL = 'https://kpfox.com'
const SKY_COLOUR = 0x081021;
const CLEAR_COLOUR = 0xffffff;
const AFTERIMAGE_DAMPING = 0.98;
const FOX_FACT_INTERVAL = 6000; // msec to show fact fact
const FOX_FACT_BLACKOUT_TIME = 1000; //how long to pause between fox facts
const HISTORY_GRAPH_REFRESH_INTERVAL = 5*60*1000; // 5 MINUNTES


init();
initKpSelectControls();
animate();

function getShaderParam(param){
  const range = kpLookup[param];
  // the third array value is the exponent to raise the linear interpolation to.
  // 1.0 means a purely linear interpolation (y=mx+b)
  // 2.0 means y=m^2.0+b etc
  const exponent = range[2]; 
  let fraction = kpSelected / 9.0;
  fraction = Math.pow(fraction, exponent);//linear was ramping up too fast this should put a dip in around kp5
  return (range[1] - range[0]) * fraction + range[0];
}

function setShaderParams(){
  controlParams = {
    f1AudioAmp: 0.0,

    t0TimeFactor: 0.2,

    f2AudioAmp: 0.0,
    f2Amp: getShaderParam('famp'),
    f2SpatialFreq: getShaderParam('fspatialfreq'),
    f2SpatialFreq2: getShaderParam('fspatialfreq2'),
    f2TimeFreq: getShaderParam('ftimefreq'),
    f2TravelRate: getShaderParam('ftravelrate'),
    f2VerticalDistributionA: getShaderParam('fverticaldista'),
    f2VerticalDistributionB: getShaderParam('fverticaldistb'),
    f2VerticalOffset: getShaderParam('fverticaloffset'),
    
    v0Amp: getShaderParam('vamp'),
    v0SpatialFreq: getShaderParam('vspatialfreq'),
    v0TimeFreq: getShaderParam('vtimefreq'),
  
    v1Amp: 0.000000,
    v1SpatialFreq: 0.005,
    v1TimeFreq: -1.0
  }
}

async function getKp() {
  const response = await fetch('https://services.swpc.noaa.gov/json/planetary_k_index_1m.json', {cache: "no-store"});
  const latest = await response.json();
  return latest;
}

async function getKpHistory() {
  const response = await fetch(BASE_URL+'/data/last_incident_of_each_kp.json');
  const latest = await response.json();
  return latest;
}

function formatDate(date){
  // YYYY-MM-DD
  const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  const [y,m,d] = date.split('-');
  return `${month[Number(m)-1]} ${d}, ${y}`;
}

function updateFoxFact() {
  // update fox fact
  currentFoxFact += 1;
  if (currentFoxFact >= foxFacts.length) {
    currentFoxFact = 0;
  }
  foxFactDirty = true;
  refreshDisplay();
}

function refreshDisplay(){
  let instructionText, descriptionText;
  if (kpSelected === null) {
    kpSelected = kpData.kp_index;
  }
  if (kpSelected != kpData.kp_index) {
    instructionText = `<span style='color: ${kpColours[kpSelected]};'>You chose Kp${kpSelected}</span>`; 
    descriptionText = descriptions[kpSelected];
  }
  else {
    instructionText = `<span style='color: ${kpColours[kpData.kp_index]};'>Current Kp index is ${kpData.kp_index}</span>`; 
    descriptionText = descriptions[kpData.kp_index];
    // let trend = kpData.kp_index - kpTrend[0][1]['kp_index'];
    // const arrowDown = '<span class="arrow-down">▼</span>';
    // const arrowUp = '<span class="arrow-up">▲</span>';
    // const arrowNeutral = '<span class="arrow-neutral">●</span>';
    // const trendStr = [
    //   `${arrowDown}${arrowDown} Kp trending strongly downward`, 
    //   `${arrowDown} Kp trending downward`, 
    //   `${arrowNeutral} Kp remaining about the same`, 
    //   `${arrowUp} Kp trending upward!`, 
    //   `${arrowUp}${arrowUp} Kp trending strongly upward!`
    // ];
    // if (trend < -2) {
    //   trend = -2;
    // } else if (trend > 2) {
    //   trend = 2;
    // }
    // descriptionText += `<br/><span class='small--hidden'>${trendStr[trend + 2]}</span>`
  }

  const lastKpLikeSelected = kpDataHistory['kp'+kpSelected]
  if(!!lastKpLikeSelected) {
    descriptionText += `<br/><span class='small'>Before today, <span style='color: ${kpColours[kpSelected]};'>Kp${kpSelected}</span> last seen on ${formatDate(lastKpLikeSelected)}</span>`
  }
  sun2.position.set(sun2.position.x, getShaderParam('sunpos'), sun2.position.z);
  const pos = curtains[0].mesh.position;
  const rot = curtains[0].mesh.rotation;
  curtains[0].mesh.position.set(getShaderParam('curtainnorth'), pos.y, pos.z);
  curtains[0].mesh.rotation.set(rot.x, rot.y, getShaderParam('curtainrotation'));
  camera.zoom = getShaderParam('zoom');
  camera.fov = getShaderParam('fov');
  camera.lookAt(new THREE.Vector3(96, getShaderParam('panup'), -2));
  camera.updateProjectionMatrix();
  
  instructions.innerHTML = instructionText;
  description.innerHTML = descriptionText;

  const kpButtons = menu.querySelectorAll('span');
  kpButtons.forEach( (button) => {
    if (button.dataset.kp != kpSelected) {
      button.classList.remove('selected');
    } else {
      button.classList.add('selected');
    }
  })
  fox.style.filter = `brightness(${getShaderParam('foxlight')}) contrast(120%)`;
  ground.style.filter = `brightness(${getShaderParam('groundlight')})`;

  if(!!foxFactDirty) {
    setTimeout(
      () => {
        foxFact.innerHTML = foxFacts[currentFoxFact];
        foxFact.style.opacity = 1.0;
      }, FOX_FACT_BLACKOUT_TIME
    )
    foxFactDirty = false;
    foxFact.style.opacity = 0.0;
  }

  

  setShaderParams();
}

function refreshHistoryGraph() {
  getKp().then((data) => {
    kpTrend = Object.entries(data);
    kpData = Object.entries(data).pop()[1];
    const sample_period_minutes = 5;
    const hours = 4;
    historyGraph.innerHTML = generateHistoryGraphHtml(kpTrend, hours, sample_period_minutes)
    historyGraphLegend.innerHTML = `Last ${hours} hours in ${sample_period_minutes} minute intervals`
    // getKpHistory().then((data) => {
    //   kpDataHistory = data;
    // });
  });
}

function generateHistoryGraphHtml(data, hours=4, sample_period_minutes=5) {
  let allEntries = Object.entries(data);
  // allEntries[allEntries.length-1] = 
  //readings are every minute for 2 hours, so this is every 5 min for 24 bars over 2 hrs
  // we have to reverse before taking every 5th one so we make sure the graph contains 
  // the latest reading no matter what!
  const samples = hours * 60 / sample_period_minutes;
  const width = 7 * 24 / samples;

  let filtered = allEntries.reverse().filter((el, index) => index % sample_period_minutes == 0).reverse();
  filtered = filtered.splice(filtered.length-samples, samples)
  let str = '';
  filtered.forEach( (entry, i) => {
    const kp = entry[1][1]['kp_index'];
    const kp_estimated = entry[1][1]['estimated_kp']; // includes two decimals in 0.33 increments
    const date = entry[1][1]['time_tag'];
    const pct = Math.floor(kp_estimated * 10 + 10);
    const colour = kpColours[kp];
    const last = filtered.length - 1 == i;
    str += `
      <div role='button' title='${date}' style='
          float: left;
          width: ${width}px;
          height: 100%;
          margin-left: 1px;
          background: linear-gradient(
            rgba(255,255,255,0.1) ${100-pct}%, 
            ${colour} ${100-pct}%
          );
          ${last?'border-bottom: 1px solid #ccc':''}
        '></div>
    `
  });
  return str;
 }

function init() {
  interactive = document.getElementById( 'interactive' );
  container = document.getElementById( 'container' );
  historyGraph = document.getElementById( 'kp-graph' );
  historyGraphLegend = document.getElementById( 'kp-graph-legend' );

  dashboard = document.getElementById( 'dashboard' );
  
  instructions = document.getElementById( 'instructions' );
  description = document.getElementById( 'description' );
  menu = document.getElementById( 'menu' );
  fox = document.getElementById( 'fox' );
  foxContainer = document.getElementById( 'fox-container' );
  foxFact = document.getElementById( 'foxfact' );
  foxFactContainer = document.getElementById( 'foxfact-container' );
  ground = document.getElementById( 'ground' );
  
  // grab the fox fact html from the DOM
  // show it in the console for those interested
  foxFacts = foxFactContainer.querySelectorAll( 'li' );
  foxFacts = [...foxFacts].map( el => el.innerHTML );
  // console.log('🦊 Fox Facts!\n' + foxFacts.join('\n'));
  currentFoxFact = -1;
  foxFactTimer = setInterval(() => {
    updateFoxFact();
  }, FOX_FACT_INTERVAL);

  historyGraphTimer = setInterval(() => {
    refreshHistoryGraph();
  }, HISTORY_GRAPH_REFRESH_INTERVAL);
  refreshHistoryGraph();

  menu.style.visibility = 'hidden';
  

	// camera 
  camera = new THREE.PerspectiveCamera( fov, window.innerWidth / window.innerHeight, 0.0005, 25000 );

	// scenes
  scene = new THREE.Scene();
  scene.background = new THREE.Color( SKY_COLOUR );

  sceneForeground = new THREE.Scene();

  sceneUniverse = new THREE.Scene();

	// moon
  var moon = new Moon();
  moon.position.set( 14000, 7000, 2000);
  const moonScale = 6000;
  moon.scale.set( moonScale, moonScale, moonScale);
  sceneUniverse.add(moon);
  
  //constellations
  var orion = new Orion();
  orion.position.set( 12000,5000,-2000);
  const orionScale = 8000;
  orion.scale.set( orionScale, orionScale, orionScale);
  sceneUniverse.add(orion);

  //sun
  // var sun = new Sun();
  // sun.position.set( 40000, -4000, -3000);
  // sun.lookAt(new THREE.Vector3(0,0,0));
  // sceneUniverse.add(sun);
  
  sun2 = new Sun();
  sun2.position.set( 70000, 3000, 0);
  sun2.lookAt(new THREE.Vector3(0,0,0));
  sceneUniverse.add(sun2);

	//aurora curtain
  curtains.push(new Curtain({scale: 1.0, width: 1000, height: 0.008, y: 0.0052, x: 0.0,  z: 0.0, points: 1200,  spiralGrowth: 0.7,  spiralRadius: 0.000025, phase:  0}));
  // curtains.push(new Curtain({width: 4500000, height: 400000, y: 300000, x: 0,   z: -2000, points: 620,  spiralGrowth: 0.5,  spiralRadius: 10000, phase:  2000000}));
  // curtains.push(new Curtain({width: 3500000, height: 900000, y: 460000, x: -500000,  z: 2000, points: 120,  spiralGrowth: 2.0,  spiralRadius: 1000, phase:  1400000}));
  curtains.forEach( c => scene.add(c.create()));
  camera.translateY(0.00037);
  camera.translateX(-0.003);
  camera.lookAt(new THREE.Vector3(96, 25, -2));

	

  //meteor
  meteor = new Meteor({scene: scene});

  var stars = new Stars({scene: sceneUniverse});

  renderer = new THREE.WebGLRenderer({ antialias: false });
  renderer.autoClear = false; // we need two renderers - one for aurora, one for FG since aurora have bo depth test (https://stackoverflow.com/questions/12666570)
  renderer.setClearColor( CLEAR_COLOUR );
	renderer.setPixelRatio( window.devicePixelRatio * pixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  
  // postprocessing
  composer = new EffectComposer( renderer );
  renderPass = composer.addPass( new RenderPass( scene, camera ) );
  afterimagePass = new AfterimagePass({damp: AFTERIMAGE_DAMPING});
  composer.addPass( afterimagePass );
  
  container.innerHTML = "";
	container.appendChild( renderer.domElement );
  window.addEventListener( 'resize', onWindowResize, false );
  window.addEventListener( 'load', onLoad, false );
}

function onLoad() {
  menu.style.visibility = 'visible';
  onWindowResize();
}

function onWindowResize() {
  // it seems that when a new mobile browser tab is opened from social media sites,
  // the address bar and bottom nav bar are counted as part of window.innerheight
  // we have to recalc after some period of time, in this case at the DOMContentLoaded
  // event. Although the following link doesn;t mention this explicitly, it does
  // refer to the issues with viewport sizes on mobile:
  // https://nicolas-hoizey.com/articles/2015/02/18/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers/

  // interactive.style.height = `${window.innerHeight}px`;
  // interactive.style.width = `${window.innerWidth}px`;
  console.log('screen resize: ',window.innerWidth, window.innerHeight);
  const ratio = window.innerWidth / window.innerHeight;
  const groundFraction = 0.20;
  const groundFromTop = Math.floor((1.0-groundFraction) * window.innerHeight);
  const foxFraction = 0.50;
  menu.style.top = `${window.innerHeight-140}px`;
  info.style.top = `${window.innerHeight-20}px`;
  ground.style.top = `${groundFromTop}px`;
  ground.style.height = `${window.innerHeight - groundFromTop}px`;
  ground.style.width = `${window.innerWidth}px`;
  let style = getComputedStyle(dashboard)
  console.log('dashboard is ', style.bottom, style.top);
  if(style.right == 0 ) {
    dashboard.style.top = `${window.innerHeight-150}px`;
  }
  foxContainer.style.top = `${Math.floor((1.0-foxFraction) * window.innerHeight)}px`;
  // console.log('window resize');
  // console.log('client height', window.clientHeight);
  // console.log('window innerheight', window.innerHeight); 
  camera.aspect = ratio;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}

function animate() {
	requestAnimationFrame( animate );
	render();
}

function render() {
  meteor.update();
	// var delta = clock.getDelta();
  time += 0.01;
  if (time > 500.0) time = 0.0;
  curtains.forEach( c => c.animate(time, controlParams));
  renderer.clear();
  renderer.clearDepth();
  composer.render(); //post processing
  renderer.render( sceneForeground, camera );
  renderer.render( sceneUniverse, camera );
}

function handleKpUpdate(e){
  e.stopPropagation();
  e.preventDefault();
  const kp = e.target.dataset.kp;
  kpSelected = kp;
  refreshDisplay();
}

function initKpSelectControls(){
  const kpButtons  = menu.querySelectorAll('span');
  kpButtons.forEach( (button) => {
    button.addEventListener('click', (e) => { handleKpUpdate(e) });
  })
}


