import * as THREE from 'three';

export default class Orion {
  constructor({} = {}) {  
    var textureLoader = new THREE.TextureLoader();
    var material = new THREE.SpriteMaterial( {
      map: textureLoader.load( 'image/textures/orion.png' ),
      blending: THREE.AdditiveBlending,
      opacity: 1.0,
      transparent: true,
      rotation: 0,
    } );
    var sprite = new THREE.Sprite(material);
    sprite.scale.set(7000, 7000, 1);
    return sprite;
  }
}