var triangle = `
float m, y;
float triangle(float center, float threshold, float preamp, float x) {
  if (x < center) {
    m = 1.0 / center;
    y = preamp * m * x;
  } else {
    m = -1.0 / (1.0 - center);
    y = preamp * m * (x - 1.0);
  }
  y = smoothstep(0.0, 1.0, y - threshold);
  return y; 
}
`
module.exports = {
  triangle: triangle
};
