import * as THREE from 'three';

export default class Sun {
  constructor({} = {}) {
    var texture = new THREE.TextureLoader().load( "image/textures/sun2.png" );
    var material = new THREE.MeshBasicMaterial( {
      map: texture,
      lights: false,
      transparent: true,
      color: 0xffffff,
      fog: false,
      blending: THREE.AdditiveBlending
    } );
    var geometry = new THREE.PlaneGeometry( 120000, 40000, 8, 8 );
    var obj = new THREE.Mesh(geometry, material);
    return obj;
  }
}