import * as THREE from 'three';
import currentPhase from './MoonPhase.js';

export default class Moon {
  constructor({} = {}) {
    const phase = currentPhase();
    console.log("calculated current moon phase: " + phase);
    var textureLoader = new THREE.TextureLoader();
    var materialMoon = new THREE.SpriteMaterial( {
      map: textureLoader.load( `image/textures/moon-${phase.split(' ').join('')}.png` ),
      blending: THREE.AdditiveBlending,
      opacity: 1.0,
      transparent: true,
      rotation: Math.PI / 5.0
    } );
    var sprite = new THREE.Sprite(materialMoon);
    sprite.scale.set(7000, 7000, 1);
    return sprite;
  }
}