// https://minkukel.com/en/various/calculating-moon-phase/

/* 
Moon Phaser Demo
By: Minkukel
Date: 18-04-2020
*/

// Use current UTC date and time for this demo
function currentPhase(){
  // date_default_timezone_set('UTC');
  // const thedate = date('Y-m-d H:i:s');
  const unixdate = +new Date(); //strtotime(thedate);
  var thephase;

  // The duration in days of a lunar cycle
  const lunardays = 29.53058770576;
  // Seconds in lunar cycle
  const lunarsecs = lunardays * (24 * 60 *60);
  // Date time of first new moon in year 2000
  const new2000 = 947200440000; //strtotime("2000-01-06 18:14");

  // Calculate seconds between date and new moon 2000
  const totalsecs = (unixdate - new2000)/1000;

  // Calculate modulus to drop completed cycles
  // Note: for real numbers use fmod() instead of % operator
  const currentsecs = Math.floor(totalsecs*10000) % Math.floor(lunarsecs*10000) / 10000.0; // js has no fmod, so using pseudo fixed

  // If negative number (date before new moon 2000) add lunarsecs
  if ( currentsecs < 0 ) {
      currentsecs += lunarsecs;
  }

  // Calculate the fraction of the moon cycle
  const currentfrac = currentsecs / lunarsecs;

  // Calculate days in current cycle (moon age)
  const currentdays = currentfrac * lunardays;

  // Array with start and end of each phase
  // In this array 'new', 'first quarter', 'full' and
  // 'last quarter' each get a duration of 2 days.
  const phases = [
      ["new", 0, 1],
      ["waxing crescent", 1, 6.38264692644],
      ["first quarter", 6.38264692644, 8.38264692644],
      ["waxing gibbous", 8.38264692644, 13.76529385288],
      ["full", 13.76529385288, 15.76529385288],
      ["waning gibbous", 15.76529385288, 21.14794077932],
      ["last quarter", 21.14794077932, 23.14794077932],
      ["waning crescent", 23.14794077932, 28.53058770576],
      ["new", 28.53058770576, 29.53058770576],
    ];

  // Find current phase in the array
  for ( let i=0; i<9; i++ ){
      if ( (currentdays >= phases[i][1]) && (currentdays <= phases[i][2]) ) {
        thephase = phases[i][0];
          break;
      }
  }
  return thephase;
}

export default currentPhase;

