import * as THREE from 'three';

export default class Meteor {
  constructor({scene={}} = {}) {
    var textureLoader = new THREE.TextureLoader();
    var material = new THREE.SpriteMaterial( {
      map: textureLoader.load( "image/textures/meteor.png" ),
      color: 0xffffff,
      sizeAttenuation: false,
      rotation: (2.146)
    } );
    this.x = -100;
    this.y = 2000;
    this.slope = Math.random();

    this.sprite = new THREE.Sprite(material);
    this.sprite.scale.set(0.03, 0.03, 0.03);
    
    this.sprite.position.set( this.y, 3000, this.x);
    scene.add(this.sprite);
    return this;
  }

  update() {
    this.x += 10;
    this.sprite.position.set( this.y, 3000 - this.x, this.x * this.slope);
    if (this.x > 4000){
      this.y = Math.random()*10000;
      this.x = -Math.random()*6000;
      this.slope = Math.random();
    }
  }
}