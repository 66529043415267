
var simpleNoise = require('./noise.js').simpleNoise;
var skewedGuassian = require('./skewedGuassian.js').skewedGuassian;
var triangle = require('./triangle.js').triangle;
var fbmNoise = require('./fbmNoise.js').fbmNoise;
import * as THREE from 'three';

export default class Curtain {

  constructor (
    { 
      segmentsHigh = 1, 
      segmentsWide = 800,
      width = 2000000,
      height = 200000,
      x = 0,
      y = 250000,
      z = 0,
      phase = Math.random() * 100000000.0,
      spiralRadius = 14000,
      spiralGrowth = 0.5,
      points = 200,
      scale = 1.0
    } = {},
    
  ) {
		this.segmentsHigh = segmentsHigh;
		this.segmentsWide = segmentsWide;
		this.x = x; // displacement along normal of curtains parallel to earth
		this.y = y; // + is away from center of earth
		this.z = z; // along length of curtain parallel to surface
		this.height = height;
		this.width = width;
    this.phase = Math.random() * 100000000.0;
    this.spiralRadius = spiralRadius;
    this.spiralGrowth = spiralGrowth;
    this.points = points;
    this.scale = scale;
	}
  
  shaderMaterial () {
    var vShader =
      `
      ${simpleNoise}
      uniform float time;
      uniform float phase;
      varying vec2 vUv;
      uniform float v0Amp;
      uniform float v0SpatialFreq;
      uniform float v0TimeFreq;
      uniform float v1Amp;
      uniform float v1SpatialFreq;
      uniform float v1TimeFreq;
      uniform float t0TimeFactor;

      void main () {
        vUv = uv;
        float t = time * 0.03;
        float mag0 = vUv.y * v0SpatialFreq * 40.0 + t * (v0TimeFreq * t0TimeFactor + phase * 0.1);
        // float mag1 = vUv.y * v1SpatialFreq * 40.0 + t * (v1TimeFreq * t0TimeFactor + phase * 0.1);

        float noise = snoise(
          vec2(mag0, mag0)
        );
        // float noise1 = snoise(
        //   vec2(mag1, mag1)
        // );
        noise *= v0Amp * 100.0;// * (vUv.y + 1.0) ;
        // noise1 *= v1Amp * 100.0;// * (vUv.y + 1.0);
        vec3 newPos = position;
        // newPos += normal * (noise + noise1);
        newPos += normal * noise;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPos, 1.0);
      }
      `

      var fShader3 =
      `
      ${skewedGuassian}
      ${fbmNoise}
      ${simpleNoise}
      ${triangle}
 
      uniform float time;
      uniform float f2Amp;
      uniform float f2SpatialFreq;
      uniform float f2SpatialFreq2;
      uniform float f2TimeFreq;
      uniform float f2TravelRate;
      uniform float f2VerticalDistributionA;
      uniform float f2VerticalDistributionB;
      uniform float f2VerticalOffset;
      uniform float phase;
      uniform float t0TimeFactor;
      varying vec2 vUv;

      uniform sampler2D emmissionTexture;

      vec4 auroraFragment(
        float time,
        float phase,
        float timeFreq,
        float spatialFreq,
        float spatialFreq2,
        float travelRate,
        float verticalDistributionA,
        float verticalDistributionB,
        float verticalOffset,
        float amp,
        float timeFactor
      ){    
        float m_time = (time * timeFactor + phase);
        vec2 st = vec2(vUv.x * 0.001 + m_time * 0.1 * timeFreq, vUv.y * spatialFreq + m_time * 0.1 * travelRate );
        vec2 q = vec2(0.);
        q.x = fbm( st );
        q.y = fbm( st + vec2(1.0));
        vec2 r = vec2(0.);
        r.x = fbm( st + 1.0 * q + vec2( 1.7, 9.2 ) ) + m_time * 0.01 * timeFreq + phase * 10.0 ;
        r.y = fbm( st + 1.0 * q + 
          vec2( 
            8.3, 
            2.8 + m_time * 0.1 * travelRate
          )
        );
        float f = fbm(st + r);
        float structure = 0.5 * f*f*f*f + 0.5 * f*f*f + 0.5 * f*f + verticalDistributionB * f;
        structure = smoothstep( 0.0, 6.0, structure );
        float tm = m_time * 0.5;
        float n = 1.0 * snoise(vec2(spatialFreq2 * vUv.y * 20.0 + tm * 0.6, (1.0 - vUv.x) * 0.2 + tm * 0.9));

        vec4 texel = texture2D( emmissionTexture, vec2(vUv.x + structure * verticalDistributionA, structure * verticalOffset) );
        
        // float center = verticalDistributionA * 0.2 * structure;
        // float clip = verticalDistributionB * structure;
        
        float a = structure; //triangle(center, clip, amp, vUv.x);
        
        texel.a *= amp * (1.0 + n * 1.2); 
        // * a ;//a * amp; // * (0.2 + smoothstep( -1.0, 1.0, n ));
        
        // texel.a *= a * (1.0 + n);
        // * (1.0-vUv.x*vUv.x*vUv.x); // * (1.0 - vUv.y*vUv.y*vUv.y);
        
        // texel.r *= texel.r;
        // texel.g *= (1.0 + texel.g * texel.g);
        // texel.b *= (1.0 - a * a * a*  10.2); 

        return texel;
      }
      
      void main() {
        vec4 tex1 = auroraFragment(
          time,
          phase,
          f2TimeFreq,
          f2SpatialFreq,
          f2SpatialFreq2,
          f2TravelRate,
          f2VerticalDistributionA,
          f2VerticalDistributionB,
          f2VerticalOffset,
          f2Amp,
          t0TimeFactor
        );
        gl_FragColor = tex1;
      }
      `

    var uniforms = {
      time: { type: "f", value: 1.0 },

      emmissionTexture: { 
        type: "t",
        value: new THREE.TextureLoader().load("./image/textures/colourgrad4.png" )
      },
      f2Amp: { type: "f", value: 0.0 },
      f2SpatialFreq: { type: "f", value: 0.0 },
      f2SpatialFreq2: { type: "f", value: 0.0 },
      f2TimeFreq: { type: "f", value: 0.0 },
      f2TravelRate: { type: "f", value: 0.0 },
      f2VerticalDistributionA: { type: "f", value: 1.0 },
      f2VerticalDistributionB: { type: "f", value: 1.0 },
      f2VerticalOffset: { type: "f", value: 0.0 },      

      v0Amp: { type: "f", value: 0.0 },
      v0SpatialFreq: { type: "f", value: 0.0 },
      v0TimeFreq: { type: "f", value: 0.0 },
      v0VerticalDistributionA: { type: "f", value: 1.0 },
      v0VerticalDistributionB: { type: "f", value: 1.0 },
      v1Amp: { type: "f", value: 0.0 },
      v1SpatialFreq: { type: "f", value: 0.0 },
      v1TimeFreq: { type: "f", value: 0.0 },
      
      t0TimeFactor: { type: "f", value: 1.0 },

      phase: { type: "f", value: Math.random() * 3.14 },
    };

    var shaderMaterial =
      new THREE.ShaderMaterial({
        uniforms: uniforms,
        fragmentShader: fShader3,
        vertexShader: vShader,
        wireframe: false,
        side: THREE.DoubleSide,
        transparent: false,
        blending: THREE.AdditiveBlending,
        depthTest: false, // http://learningwebgl.com/blog/?p=859 This is a little more interesting; we have to switch off depth testing. If we don’t do this, then the blending will happen in some cases and not in others. 
        lights: false,
      });
    return shaderMaterial;
  }

	create() {
		this.geometry = new THREE.PlaneGeometry( this.height, this.width, this.segmentsHigh, this.segmentsWide );
    this.geometry.rotateX( Math.PI / 2.0 );
    this.geometry.rotateZ( Math.PI / 2.0 );

    this.material = this.shaderMaterial();
    
    this.mesh = new THREE.Mesh( this.geometry, this.material );

    // uncomment to see normal helpers
    // this.faceNormalsHelper = new THREE.FaceNormalsHelper( this.mesh, 10000 );
    // this.mesh.add( this.faceNormalsHelper );
    // this.vertexNormalsHelper = new THREE.VertexNormalsHelper( this.mesh, 10000 );
    // this.mesh.add( this.vertexNormalsHelper )
    this.geometry.scale(this.scale,this.scale,this.scale);
    this.geometry.translate(this.x, this.y, this.z);
    this.splineWarp();
    this.skew({away: 0.0009, perpendicular: 0.004});
    // this.verticalWarp(0.003);
    // this.coneWarp(0.001);
    return this.mesh;
  }

  getMesh(){
    return this.mesh;
  }
  
  coneWarp(scale) {
    var v = this.geometry.vertices;
    var stripNum = 0; // strip run from the base of the curtain upwards
    for ( var i = 0; i < v.length; i ++ ) {
      stripNum = Math.floor( i / 2 );
      let pos = stripNum / (v.length );
      // vanishing point: curve of earth simulation curves tha ends of the arc downwards:
      if(i <= v.length ){
        v[i].y += pos * scale; 
      } else {
        v[i].y += (1.0 - pos) * scale;
      }
    }
  }

  verticalWarp (horizonDist = 2000000.0) {
    var altitude = this.y;
    var v = this.geometry.vertices;
    var stripNum = 0; // strip run from the base of the curtain upwards
    var offset = 0;
    var distFromCamera = 0;
    for ( var i = 0; i < v.length; i ++ ) {
      stripNum = Math.floor( i / 2 );
      // vanishing point: curve of earth simulation curves tha ends of the arc downwards:
      distFromCamera = Math.sqrt( v[i].x * v[i].x + v[i].z * v[i].z );
      offset = distFromCamera / horizonDist;
      offset = altitude * (offset - 1.0);
      v[i].y -= offset;
    }
  }

  horizontalWarp (period = 2000000.0, amplitude = 100000.0) {
    var v = this.geometry.vertices;
    var stripNum = 0; // strip run from the base of the curtain upwards
    var offset = 0;
    var distFromCamera = 0;
    for ( var i = 0; i < v.length; i ++ ) {
      stripNum = Math.floor( i / 2 );
      // vanishing point: curve of earth simulation curves tha ends of the arc downwards:
      distFromCamera = v[i].x + v[i].z;
      offset = Math.sin(distFromCamera / period * Math.PI * 2.0);
      offset += Math.sin(distFromCamera / period * Math.PI * 2.0 + Math.PI * 0.5);
      v[i].x += offset * amplitude;
    }
  }

  splineWarp(){
    // Create a sine-like wave
    var spiral = [];
    const r = this.spiralRadius;
    const k = 0.4;
    const nmax = this.points;
    const spread = this.spiralGrowth;
    const spacing = 0.25;
    var sqrt;

    for (var n = nmax; n > 20; n--){
      sqrt = Math.sqrt(k * n);
      var p = new THREE.Vector2(
        -Math.pow(n, spread) * r * spacing * sqrt * Math.cos(sqrt),
        -Math.pow(n, spread) * r * spacing * sqrt * Math.sin(sqrt)
      );
      spiral.push(p);
    }
    for (var n = 20; n <= nmax; n++){
      sqrt = Math.sqrt(k * n);
      var p = new THREE.Vector2(
        Math.pow(n, spread) * r * spacing * sqrt * Math.cos(sqrt),
        Math.pow(n, spread) * r * spacing * sqrt * Math.sin(sqrt)
      );
      spiral.push(p);
    }

    var curve = new THREE.SplineCurve(spiral);

    var points = curve.getPoints( this.segmentsWide );
    var stripNum = 0;
    var v = this.geometry.vertices; 
    for ( var i = 0; i < v.length; i++ ) {
      let vertex = v[i];
      stripNum = Math.floor(i/2);
      vertex.x = points[stripNum].x + this.x;
      vertex.z = points[stripNum].y;
    }

    this.geometry.rotateY(1.57);
  }

  skew(params = {away: 0.0, perpendicular: 0.0}) {
    // Initialization. This temporary matrix can be shared.
    var v = this.geometry.vertices; 

    for ( var i = 0; i < v.length; i++ ) {
      let pos = i/v.length - 0.5;
      let vertex = v[i];
      
      // skew if we want any:
      if ( i % 2 ) {
        vertex.x += params.away;
        vertex.z += params.perpendicular;
      }
    }

  }
 
	animate(tick, params) {
    this.mesh.material.uniforms.time.value = tick;
    this.mesh.material.uniforms.t0TimeFactor.value = params.t0TimeFactor;
    
    this.mesh.material.uniforms.v0Amp.value = params.v0Amp;
    this.mesh.material.uniforms.v0SpatialFreq.value = params.v0SpatialFreq;
    this.mesh.material.uniforms.v0TimeFreq.value = params.v0TimeFreq;

    this.mesh.material.uniforms.v1Amp.value = params.v1Amp;
    this.mesh.material.uniforms.v1SpatialFreq.value = params.v1SpatialFreq;
    this.mesh.material.uniforms.v1TimeFreq.value = params.v1TimeFreq;

    this.mesh.material.uniforms.f2Amp.value = params.f2Amp;
    this.mesh.material.uniforms.f2SpatialFreq.value = params.f2SpatialFreq;
    this.mesh.material.uniforms.f2SpatialFreq2.value = params.f2SpatialFreq2;
    this.mesh.material.uniforms.f2TimeFreq.value = params.f2TimeFreq;
    this.mesh.material.uniforms.f2TravelRate.value = params.f2TravelRate;
    this.mesh.material.uniforms.f2VerticalDistributionA.value = params.f2VerticalDistributionA;
    this.mesh.material.uniforms.f2VerticalDistributionB.value = params.f2VerticalDistributionB;
    this.mesh.material.uniforms.f2VerticalOffset.value = params.f2VerticalOffset;
    // this.geometry.rotateY(0.0003);

    // uncomment to update normal helpers
    // this.vertexNormalsHelper.update();
    // this.faceNormalsHelper.update();
	}
}